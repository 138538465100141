import React, { useEffect } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup, chakra } from '@chakra-ui/react'
import Date, { UncontrolledDate } from '../../../../../molecules/controls/Date'
import dayjs from 'dayjs'
import { updateEvent } from '../../../../../../../api/app/events'
import { useEventContext } from '../../../../../../contexts/EventProvider'

const schemaDeliveryDate = Yup.object().shape({
	deliveryDate: Yup.string().required().nullable(),
})

export const FormScheduleDelivery = ({ onClose, onSubmit, formData, label, ...rest }) => {
	const { event } = useEventContext()
	const dispatch = useDispatch()

	/** Define methods and pass in initial state right away that will be used through the form context */
	const { handleSubmit, control, errors, setValue, watch, register } = useForm({
		mode: 'onSubmit',
		defaultValues: {
			deliveryDate: null,
		},
		resolver: yupResolver(schemaDeliveryDate),
	})

	const values = watch()

	const handleDeliveryDateSubmit = (currentFormData) => {
		if (dayjs(currentFormData.deliveryDate).isValid()) {
			// Trigger callback submit
			let dayjsDate = dayjs(currentFormData.deliveryDate)

			/**
			 * If we've provided an onSubmit, then we expect to allow get
			 * the data from the previous form, then submit the entire form.
			 */
			if (onSubmit) {
				let data = { ...formData, ...currentFormData }
				// Overwrite/format the delivery Date
				data.deliveryDate = dayjsDate.toISOString()
				onSubmit(data)
			} else {
				/**
				 * If we didn't get a submit handler, then let's simply update
				 * the date on the event object.
				 */
				let eventData = {
					id: event.id,
					deliveryDate: dayjsDate.toISOString(),
				}
				dispatch(updateEvent(eventData))
			}
		}

		onClose()
	}

	/**
	 * Fill default date based on existing delivery date from the event object
	 */
	useEffect(() => {
		if (event.deliveryDate && event.deliveryDate != values.deliveryDate) {
			setValue('deliveryDate', event.deliveryDate)
		}
	}, [event.deliveryDate])

	/**
	 * Trigger the form submit when the new input date is different than the previous one
	 */
	// useEffect(() => {
	// 	if (values.deliveryDate && values.deliveryDate != '' && values.deliveryDate != event.deliveryDate) {
	// 		handleDeliveryDateSubmit(values)
	// 	}
	// }, [values.deliveryDate])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(handleDeliveryDateSubmit)} {...rest}>
			<Date
				control={control}
				errors={errors}
				label={label}
				name="deliveryDate"
				placeholder="Choose date"
				hideCurrent={true}
				useTime
			/>
			{values.deliveryDate && (
				<ButtonGroup mt="1rem">
					<Button variant="cancel" onClick={() => onClose()}>
						Cancel
					</Button>
					<Button type="submit">Schedule</Button>
				</ButtonGroup>
			)}
		</chakra.form>
	)
}

FormScheduleDelivery.defaultProps = {
	label: 'Set delivery date',
}

export default FormScheduleDelivery
