import React, { useRef } from 'react'
import { NavCard, NavCardActions, NavCardButton, NavCardContent, NavCardCover } from '../../../../atoms/NavigationCard'
import { chakra, useDisclosure } from '@chakra-ui/react'
import { useReactToPrint } from 'react-to-print'
import { useEventContext } from '../../../../../contexts/EventProvider'
import QRCodeToPrint from './QRCode'
import ModalQRCode from '../../../../organisms/ModalQRCode'
import { InfoRounded, Print } from '../../../../atoms/Icon'

const QRCodeSelector = ({ ...rest }) => {
	const { event } = useEventContext()
	const componentRef = useRef()
	const qrModal = useDisclosure()

	const handleDownloadQRCode = () => {
		handlePrint()
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	})

	const triggerModal = (e) => {
		e.stopPropagation()
		qrModal.onOpen()
	}

	const onClick = handleDownloadQRCode

	const path = '/assets/images/graphics/navigation-cards/share-recipient-QR-card'
	const title = 'Get QR Code'
	const description = 'They simply scan and watch.'

	return (
		<>
			<NavCard onClick={onClick} {...rest}>
				<NavCardCover path={path} />
				<NavCardContent
					title={
						<>
							{title}{' '}
							<InfoRounded
								ml="0.25rem"
								cursor="pointer"
								width=".875rem"
								height=".875rem"
								mt="-0.125rem"
								color="link"
								d="inline-flex"
								onClick={triggerModal}
							/>
						</>
					}
					description={description}
				/>
				<NavCardActions>
					<NavCardButton aria-label={title} onClick={onClick}>
						<Print />
					</NavCardButton>
				</NavCardActions>
			</NavCard>

			<chakra.div display="none">
				<QRCodeToPrint
					ref={componentRef}
					stringToConvert={'https://share.vidday.com/e/' + event.uuid + '?v=' + event.privateKey}
				/>
			</chakra.div>

			<ModalQRCode
				isOpen={qrModal.isOpen}
				onClose={qrModal.onClose}
				title="QR Code"
				description="The recipient can quickly scan the QR code to visit the private link."
				step1="Download and print"
				step2="Attach it to a gift or card"
			/>
		</>
	)
}

export default QRCodeSelector
