import React, { memo } from 'react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	SimpleGrid,
	Flex,
} from '@chakra-ui/react'

const ModalQRCode = memo(({ onClose, isOpen, title, description, step1, step2 }) => {
	const img1 = useImageGenerator({
		path: '/assets/images/graphics/gift-page-qr-modal-print',
		ext: 'png',
	})

	const img2 = useImageGenerator({
		path: '/assets/images/graphics/gift-page-qr-modal-add',
		ext: 'png',
	})

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Heading size="lg" variant="hero">
							{title}
						</Heading>
						<Text>{description}</Text>
						<SimpleGrid spacing="1rem" columns={[1, 2]}>
							<Box borderRadius="base" borderColor="#E6E8EB" borderWidth="1px" p="1rem">
								<HStack spacing="1rem" alignItems="center">
									<Flex alignItems="center" maxW="3.5rem">
										<Image alt="Step 1" src={img1.src} srcSet={img1.srcset} />
									</Flex>
									<Flex alignItems="flex-start" direction="column">
										<Text textAlign="left" fontWeight="bold">
											Step 1:
										</Text>
										<Text textAlign="left">{step1}</Text>
									</Flex>
								</HStack>
							</Box>

							<Box borderRadius="base" borderColor="#E6E8EB" borderWidth="1px" p="1rem">
								<HStack spacing="1rem" alignItems="center">
									<Flex alignItems="center" maxW="3.5rem">
										<Image alt="Step 2" src={img2.src} srcSet={img2.srcset} />
									</Flex>
									<Flex alignItems="flex-start" direction="column">
										<Text textAlign="left" fontWeight="bold">
											Step 2:
										</Text>
										<Text textAlign="left">{step2}</Text>
									</Flex>
								</HStack>
							</Box>
						</SimpleGrid>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							onClick={() => {
								onClose()
							}}>
							Okay, Thanks
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalQRCode
