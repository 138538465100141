import React from 'react'
import { Navigate, Link as ReactLink } from 'react-router-dom'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Link,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'

export const ModalPrivateLinkInfo = ({ isOpen, onClose }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/modals/vidday-deliver-to-recipient-private-link',
		ext: 'png',
	})
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem" mb="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Private Link" boxSize="60px" />
						<Heading size="lg" variant="hero">
							Private Link
						</Heading>
					</VStack>
					<Box display="grid" textAlign="center">
						<Text>
							From the private link, the recipient can watch their video, record a thank you message, and
							redeem their gift card.
						</Text>
						<Text mt="1rem">
							Want to share the video with everyone else? <br />
							<Link variant="link" as={ReactLink} to={handlers.root + '/send/general'}>
								Get the public link
							</Link>
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button onClick={onClose}>Okay, Thanks</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
