import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import FormScheduleDelivery from '../../pages/SendToRecipient/RecipientDeliveryCard/src/FormScheduleDelivery'

const ModalSetDeliveryDate = ({ onClose, isOpen, onSubmit, formData }) => {
	const img = useImageGenerator({
		path: '/assets/images/icons/spiral_calendar',
		ext: 'png',
	})

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} width="80px" alt="Set a delivery date" />
						<Heading size="lg" variant="hero">
							Schedule a Delivery
						</Heading>
						<FormScheduleDelivery label="" onClose={onClose} onSubmit={onSubmit} formData={formData} />
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalSetDeliveryDate
