import { useEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthProvider'
import { useEventContext } from '../contexts/EventProvider'
import { useEventOccasionLabel } from './useEventOccasionLabel'

const generateMessage = (creatorFullName, label) => {
	return `${creatorFullName} made a VidDay video for your ${label}`
}

/**
 * Generate default Delivery Message
 * @returns delivery message for recipient
 */
const useDefaultDeliveryMessage = () => {
	const { profile } = useAuthContext()
	const { event } = useEventContext()
	const label = useEventOccasionLabel()
	const creatorFullName = profile ? `${profile.firstName} ${profile.lastName}` : 'Your friends'
	const [message, setMessage] = useState(generateMessage(creatorFullName, label))
	useEffect(() => {
		setMessage(generateMessage(creatorFullName, label))
	}, [profile, label])
	return event.deliveryMessage || message
}

export default useDefaultDeliveryMessage
