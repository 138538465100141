import React from 'react'
import NavBar from '../../atoms/NavBar'
import BackButton from '../../atoms/nav-controls/BackButton'
import PresentationButton from '../../atoms/nav-controls/PresentationButton'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useNavigate } from 'react-router-dom'
import { chakra, VStack, Box, Text, HStack, Container, useDisclosure } from '@chakra-ui/react'
import QRCodeSelector from './selectors/QRCodeSelector'
import FooterBase from '../../molecules/FooterBase'
import { ModalPrivateLinkInfo } from '../../organisms/ModalPrivateLinkInfo'
import RecipientDelivery from './RecipientDeliveryCard'
import { InfoRounded } from '../../atoms/Icon'
import TheatreHeader from '../../molecules/TheatreHeader'
import SharingOptionsCard from '../../molecules/SharingOptionsCard'

const SendToRecipient = () => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	const handleBack = () => navigate(-1) //handlers.root
	const handlePresent = () => navigate(handlers.public_root)
	const privateLinkInfoModal = useDisclosure()
	const togglePrivateLinkInfoModal = (e) => {
		e.stopPropagation()
		privateLinkInfoModal.onOpen()
	}

	return (
		<>
			<NavBar>
				<HStack spacing={['4%', '1rem']}>
					<BackButton onClick={handleBack} />
				</HStack>
				{/* <HStack spacing={['4%', '1rem']} justifyContent="flex-end">
					<PresentationButton onClick={handlePresent} />
				</HStack> */}
			</NavBar>

			<Container maxW="container.lg" variant="main" px="1rem">
				<Box mb="1rem">
					<TheatreHeader title="Deliver to Recipient" />
				</Box>

				<Box mb="2rem" textAlign={['center']}>
					<VStack spacing="0.5rem" alignItems={['center']}>
						<Text>
							<chakra.strong>Important, read carefully:</chakra.strong>
							<br />
							Only send the private link to the recipient.
							<InfoRounded
								ml="0.25rem"
								cursor="pointer"
								width=".875rem"
								height=".875rem"
								mt="-0.125rem"
								color="link"
								d="inline-flex"
								onClick={togglePrivateLinkInfoModal}
							/>
						</Text>
					</VStack>
				</Box>

				<RecipientDelivery />

				<SharingOptionsCard role="creator" toRecipient={true} mb="1rem" />

				<Box
					sx={{
						w: 'full',
						pos: 'relative',
						transformStyle: 'preserve-3d',
						overflow: 'visible',
						mb: '5rem',

						_before: {
							backgroundSize: 'cover',
							backgroundImage: 'url(/assets/images/bg/footer-confetti.svg)',
							backgroundRepeat: 'no-repeat',
							w: ['100%', '45%'],
							maxW: '500px',
							h: '65px',
							content: '""',
							pos: 'absolute',
							bottom: '-52px',
							left: '50%',
							zIndex: '-1',
							transform: 'translateZ(-1px) translateX(-50%)',
						},
					}}>
					<QRCodeSelector />
				</Box>

				<FooterBase />
			</Container>
			<ModalPrivateLinkInfo isOpen={privateLinkInfoModal.isOpen} onClose={privateLinkInfoModal.onClose} />
		</>
	)
}

export default SendToRecipient
