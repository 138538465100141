import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { Image, chakra } from '@chakra-ui/react'
import { VideoCameraFrontRounded, ContentCutRounded } from '../../../../atoms/Icon'

const QRGenerator = ({ stringToConvert, ...rest }) => {
	return (
		<QRCodeSVG
			id="qrCode"
			value={stringToConvert}
			level="H"
			size={132}
			bgColor="#FFFFFF"
			fgColor="#000000"
			includeMargin={false}
			renderas="svg"
			{...rest}
		/>
	)
}

const QRCodeToPrint = React.forwardRef((props, ref) => {
	const { stringToConvert } = props
	return (
		<chakra.div ref={ref}>
			<chakra.div
				sx={{
					w: '8.5in',
					h: '11in',
					bg: 'white',
				}}>
				<chakra.div
					sx={{
						pos: 'relative',
						w: '216px',
						h: '324px',
						mx: 'auto',
						top: '50%',
						transform: 'translateY(-50%)',
					}}>
					<ContentCutRounded
						sx={{
							transform: 'rotate(34deg)',
							pos: 'absolute',
							top: '-20px',
							left: '20px',
							w: '24px',
							h: '24px',
							color: 'rgba(0,0,0,0.5)',
						}}
					/>

					<chakra.div
						sx={{
							pos: 'relative',
							py: '32px',
							px: '18px',
							bg: 'white',
							border: '1px dashed rgba(0, 0, 0, 0.5)',
							mx: 'auto',
							w: '216px',
							h: '324px',
							textAlign: 'center',
							color: 'black',
						}}>
						<chakra.div
							sx={{
								mx: 'auto',
								maxW: '170px',
							}}>
							<VideoCameraFrontRounded
								sx={{
									mb: 0,
									w: '24px',
									h: '24px',
								}}
							/>

							<chakra.p
								sx={{
									fontWeight: 'bold',
									fontSize: '14px',
									color: 'black',
									m: '0',
									mb: '24px',
								}}>
								Scan to watch a video made just for you.
							</chakra.p>
						</chakra.div>
						<chakra.div display="flex" justifyContent="center" mb="24px">
							<QRGenerator stringToConvert={stringToConvert} />
						</chakra.div>

						<Image
							src="/assets/images/qr-code-template-vidday-logo-black.svg"
							width="46px"
							height="13.56px"
							mx="auto"
						/>
					</chakra.div>
				</chakra.div>
			</chakra.div>
		</chakra.div>
	)
})

export default QRCodeToPrint
