import React, { useState } from 'react'
import { useEventContext } from '../../../../contexts/EventProvider'
import { chakra, Box, Image, Text, Button, useDisclosure, VStack } from '@chakra-ui/react'
import useImageGenerator from '../../../../hooks/useImageGenerator'
import { useEventRecipients } from '../../../../hooks/useRecipients'
import useMultipleImages from '../../../../hooks/useMultipleImages'
import FormDelivery from './src/FormDelivery'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schemaRecipientDelivery from './src/schemaRecipientDelivery'
import { EditRounded, ModeEditOutlineRounded } from '../../../atoms/Icon'
import Card from '../../../atoms/Card'
import dayjs from 'dayjs'
import RecipientDeliveryList from './src/RecipientDeliveryList'
import { useDispatch } from 'react-redux'
import { sendPrivatePublishedPage } from '../../../../../api/app/behaviors/current-event'
import ModalSetDeliveryDate from '../../../organisms/ModalSetDeliveryDate'
import ModalDeliverNow from '../../../organisms/ModalDeliverNow'
import CheckExMark from '../../../atoms/CheckExMark'

/**
 * Component containing the Header of the send Recipient page
 */
const Header = () => {
	const path = useMultipleImages(
		'/assets/images/graphics/parachute-envelopes-mobile',
		'/assets/images/graphics/parachute-envelopes'
	)
	const deliverHeader = useImageGenerator({
		path: path,
		ext: 'png',
	})

	return (
		<Box
			as="figure"
			w={['full']}
			h={['6.5rem', '4rem']}
			mb="1rem"
			borderTopRightRadius="xl"
			borderTopLeftRadius="xl"
			overflow="visible"
			position="relative"
			bg="blue.500">
			{path && (
				<Image
					maxW={['320px', '368px']}
					marginTop="-1rem"
					right={['1.5rem', '2rem']}
					position="absolute"
					src={deliverHeader.src}
					srcSet={deliverHeader.srcset}
					alt="Deliver to Recipient"
				/>
			)}
		</Box>
	)
}

/**
 * Form that contains list of recipients and information as well as message to share.
 * @returns
 */
const DeliveryFormProvider = ({ deliverNowModal, setEditMode }) => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const maxChars = 120 // allow room for 40 chars of event link

	/** Define methods and pass in initial state right away that will be used through the form context */
	const methods = useForm({
		// criteriaMode: 'formLevel',
		mode: 'onSubmit',
		// reValidateMode: 'onChange',
		defaultValues: {
			deliveryMessage: null,
			autoNotifyContributors: false,
			viaEmail: false,
			viaSms: false,
			recipients: [
				{
					firstName: null,
					lastName: null,
					deliveryEmail: null,
					confirmDeliveryEmail: null,
					deliverySms: null,
					confirmDeliverySms: null,
					id: null,
				},
			],
			comconsent: false,
		},
		resolver: yupResolver(schemaRecipientDelivery(maxChars)),
	})

	return (
		<>
			<Box mb="1rem">
				<Text fontSize="1.75rem" lineHeight="2rem" variant="title" fontWeight="700" textAlign="left">
					For: {recipients.firstname}{' '}
					{/*<EditRounded fontSize="1rem" color="blue.500" onClick={handleEditRecipientName} /> */}
				</Text>
			</Box>
			<FormProvider {...methods}>
				<FormDelivery
					deliverNowModal={deliverNowModal}
					setEditMode={setEditMode}
					event={event}
					recipients={recipients.raw}
					maxChars={maxChars}
				/>
			</FormProvider>
		</>
	)
}

/**
 * Component that allow to edit the date the delivery is scheduled,
 * and provide selection through a modal.
 * @returns
 */
const ScheduleDeliveryDatePicker = ({ isEditMode }) => {
	const { event } = useEventContext()
	const setDeliveryModal = useDisclosure()

	const scheduledDate = () => {
		if (dayjs().isAfter(dayjs(event.deliveryDate))) {
			return (
				<Text color="red" as="b" onClick={setDeliveryModal.onOpen}>
					Your date was set in the past please select a date in the future or deliver now.
					<ModeEditOutlineRounded title="Change Date" ml=".25rem" mt="-3px" w="1rem" h="1rem" color="link" />
				</Text>
			)
		} else {
			return (
				<Box textAlign="center" mb="1rem">
					<VStack spacing="0" alignItems="center" justifyContent="center">
						<CheckExMark render={true} check={true} scale={0.3} />
						<Text fontSize="1.5rem" variant="title">
							<chakra.strong>Scheduled</chakra.strong>
						</Text>
					</VStack>
					<Text onClick={setDeliveryModal.onOpen}>
						{dayjs(event.deliveryDate).format('ddd, MMM D, YYYY')} at{' '}
						{dayjs(event.deliveryDate).format('h:mm a')}{' '}
						{isEditMode && (
							<ModeEditOutlineRounded
								title="Change Date"
								ml=".25rem"
								mt="-3px"
								w="1rem"
								h="1rem"
								color="link"
							/>
						)}
					</Text>
				</Box>
			)
		}
	}

	return (
		<>
			{scheduledDate()}

			{event.autoNotifyContributors && (
				<Box mb="1rem" textAlign={'center'}>
					<Text mt=".5rem" fontSize=".75rem">
						Notifications will be sent 30 minutes after the recipient opens their link.
					</Text>
				</Box>
			)}

			<ModalSetDeliveryDate event={event} isOpen={setDeliveryModal.isOpen} onClose={setDeliveryModal.onClose} />
		</>
	)
}

/**
 * Component that returns the "Delivered" state of the Schedule Delivery program
 * @returns jsx
 */
const Delivered = () => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()

	return (
		<>
			<Box m="1rem" textAlign={'center'}>
				<Text fontWeight="bold" variant="title">
					Sent!
				</Text>
				<Text>{dayjs(event.deliveredAt).format('ddd, MMM D, YYYY - h:mma')}</Text>
				{event.autoNotifyContributors && (
					<Text mt=".5rem" fontSize=".75rem">
						Notifications will be sent 30 minutes after the recipient opens their link.
					</Text>
				)}
			</Box>
			<RecipientDeliveryList event={event} recipients={recipients.raw} />
		</>
	)
}

/**
 * View that allow user to go through the delivery date selection and notification
 * @returns jsx
 */
const ScheduleDelivery = () => {
	const dispatch = useDispatch()
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const deliverNowModal = useDisclosure()

	const [isEditMode, setEditMode] = useState(!event.deliveryDate)

	const handleDeliverNow = () => {
		dispatch(sendPrivatePublishedPage(event.id))
	}

	return (
		<>
			{event.deliveryDate && <ScheduleDeliveryDatePicker isEditMode={isEditMode} />}

			{event.deliveryDate && !isEditMode && (
				/** Display the list if not in edit mode */
				<>
					<RecipientDeliveryList event={event} recipients={recipients.raw} />
					{!event.deliveredAt && (
						<Box my="1rem" w="full" textAlign="center">
							<Button size="xs" variant="outline" minW="120px" onClick={() => setEditMode(true)}>
								Edit
							</Button>
						</Box>
					)}
				</>
			)}

			{isEditMode && (
				/** Display the form if we don't have a date */
				<DeliveryFormProvider deliverNowModal={deliverNowModal} setEditMode={setEditMode} />
			)}

			{/** Render modal to allow delivery now */}
			<ModalDeliverNow
				event={event}
				recipients={recipients.raw}
				isOpen={deliverNowModal.isOpen}
				onClose={() => {
					setEditMode(true), deliverNowModal.onClose(true)
				}}
				onSubmit={handleDeliverNow}
			/>
		</>
	)
}

/**
 * Root component
 * @returns jsx
 */
const RecipientDelivery = () => {
	const { event } = useEventContext()

	return (
		<Card flexDirection="column" alignItems="center" mb="1rem" overflow="visible">
			<Header />
			<Box w="full" px={['1rem', '2rem']} pt={['1rem', '2rem']} mb="1rem" mx={['1rem', 'auto']}>
				{event.deliveredAt ? <Delivered /> : <ScheduleDelivery />}
			</Box>
		</Card>
	)
}

export default RecipientDelivery
