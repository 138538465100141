import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Box,
	SimpleGrid,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'

const ModalDeliverNow = ({ recipients, onClose, isOpen, onSubmit }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-theatre-modal-deliver-now',
		ext: 'png',
	})
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image w="4rem" src={img.src} srcSet={img.srcset} alt="Deliver to recipient" />
						<Heading as="h2" size="lg">
							Deliver now?
						</Heading>
						<Text>You are about to send the private link to:</Text>
						<Box m="1rem">
							<VStack spacing="1rem">
								{recipients?.map((r, i) => {
									if (r.deliveryEmail || r.deliverySms) {
										return (
											<HStack key={i} columns={[2]} spacing="1rem" w="full">
												<Box width="20%">
													<Text fontSize="sm" textAlign="left" variant="title">
														{r.firstName}
													</Text>
												</Box>
												<Box width="85%">
													<Text fontSize="sm" textAlign="left" variant="title">
														{r.deliveryEmail}
													</Text>
													<Text fontSize="sm" textAlign="left" variant="title">
														{r.deliverySms}
													</Text>
												</Box>
											</HStack>
										)
									}
								})}
							</VStack>
						</Box>
						<Text>
							Please ensure you entered the correct email. VidDay is not liable if you enter an incorrect
							email.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button minW="100px" variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							minW="100px"
							onClick={(e) => {
								onSubmit(e)
								onClose()
							}}>
							Send
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDeliverNow
