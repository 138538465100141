import React from 'react'
import { useDispatch } from 'react-redux'
import { VStack, Box, Text, Button, useDisclosure, Flex, HStack, StackDivider } from '@chakra-ui/react'
import { sendPrivatePublishedPage } from '../../../../../../../api/app/behaviors/current-event'
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '../../../../../atoms/Icon'

const RecipientDeliveryList = ({ recipients, event }) => {
	const dispatch = useDispatch()
	const buttonStyles = {
		_focus: {
			boxShadow: 'none',
		},
	}
	const truncate = {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: 'fill-available',
		display: 'block',
	}
	const handleResendInvite = () => {
		dispatch(sendPrivatePublishedPage(event.id))
	}

	const deliveredList = recipients?.map((r, i) => {
		const recipientDrawer = useDisclosure()
		if (r?.deliveryEmail || r?.deliverySms) {
			return (
				<Box key={i} w="full">
					<Flex justify="space-between">
						<VStack
							alignItems="flex-start"
							spacing="0"
							sx={{
								'& *': {
									userSelect: 'all !important',
								},
								'overflow': 'hidden',
							}}>
							<Text sx={truncate} fontSize="0.875rem" fontWeight="bold" noOfLines={1}>
								{r.firstName}
							</Text>
							<Text sx={truncate} fontSize="0.875rem" noOfLines={1}>
								To:{' '}
								{r.deliveryEmail && (
									<>
										<strong>{r.deliveryEmail}</strong> - Sent from: vidday@vidday.com
									</>
								)}
							</Text>
							<Text sx={truncate} fontSize="0.875rem" noOfLines={1}>
								To:{' '}
								{r.deliverySms && (
									<>
										<strong>{r.deliverySms}</strong> - Sent from: 1.877.764.3210
									</>
								)}
							</Text>
						</VStack>
						<Flex align="center" ml="1rem">
							{recipientDrawer.isOpen ? (
								<KeyboardArrowUpRounded ml="1rem" onClick={() => recipientDrawer.onToggle()} />
							) : (
								<KeyboardArrowDownRounded ml="1rem" onClick={() => recipientDrawer.onToggle()} />
							)}
						</Flex>
					</Flex>
					{recipientDrawer.isOpen && (
						<Box
							w="full"
							borderTopWidth="1px"
							borderTopStyle="dashed"
							borderTopColor="#DADDE1"
							mt="0.5rem"
							pt="0.75rem"
							pb="0.5rem">
							<Text>{event.deliveryMessage}</Text>
							<HStack
								w="full"
								mt=".5rem"
								spacing={['0.75rem', '0.75rem']}
								overflowX="auto"
								sx={{
									'scrollbarWidth': 'none',
									' msOverflowStyle': 'none',
									'&::-webkit-scrollbar': {
										display: 'none',
									},
									'& *': {
										minW: 'auto !important',
									},
								}}>
								{event.deliveredAt && (
									<Button
										sx={buttonStyles}
										size="xs"
										variant="link"
										onClick={() => handleResendInvite()}>
										Resend Gift Message
									</Button>
								)}
							</HStack>
						</Box>
					)}
				</Box>
			)
		} else {
			;<></>
		}
	})
	return (
		<VStack
			w="full"
			mt="1rem"
			borderTopWidth="1px"
			borderTopColor="#DADDE1"
			pt="0.5rem"
			divider={<StackDivider borderColor="#DADDE1" />}>
			{deliveredList}
		</VStack>
	)
}

export default RecipientDeliveryList
